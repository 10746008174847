import angular from 'angular';
import get from 'lodash/get';
import filter from 'lodash/filter';
import immutable from 'seamless-immutable';

import { fetchAuthorizationsForUser } from '@skryv/core-ng1/core/store/actions/authorizations';
import { fetchDossierComments } from '@skryv/core-ng1/core/store/actions/dossiercomments';
import { updateDossier } from '@skryv/core-ng1/core/store/actions/dossiers';
import { selectDossierDetails, selectMilestonesForDossier } from '@skryv/core-ng1/core/store/selectors/dossiers';
import { selectPinnedDossierComments } from '@skryv/core-ng1/core/store/selectors/dossiercomments';
import { fetchDossierDetails, fetchDossierMilestones } from '@skryv/core-ng1/core/store/actions/dossiers';
import { checkAuthorizationForUiComponent } from '@skryv/core-ng1/core/store/actions/authorizations';

import documentDefinitionKeys from '@skryv/bundle/customizations/constants/documentDefinitionKeys';

import template from './Rentesubsidie.html';
import './Rentesubsidie.scss';

const namespace = 'veka/components/dossier/Rentesubsidie';

angular
  .module(namespace, [])
  .component('rentesubsidie', {
    template,
    bindings: {
      apiDossier: '<',
      dosdef: '<'
    },
    controller: function ($ngRedux, $filter, $state, $timeout, userService, notifications) {
      'ngInject';
      const $ctrl = this;
      const disconnect = $ngRedux.connect(mapStateToThis, { fetchDossierComments, updateDossier, fetchDossierMilestones, fetchDossierDetails, checkAuthorizationForUiComponent, fetchAuthorizationsForUser })(this);
      this.$onDestroy = disconnect;
      this.$onInit = () => {
        this.processStepsOpen = false;
        this.dossierId = get(this.apiDossier, ['id']);

        this.loadingInitialDetails = true;
        this.fetchDossierDetails(this.dossierId).then(() => this.loadingInitialDetails = false);
        this.fetchDossierComments(this.dossierId);
        this.fetchDossierMilestones(this.dossierId);
      };

      this.loadDossierInformation = () => {
        this.dossierInfo = getDossierInformation();
      };

      // important milestones
      this.goToHistoryPage = () => {
        $state.go('dossierHistory', { dossierId: this.dossierId }, { reload: false });
      };

      function mapStateToThis(state) {
        let newDossier = selectDossierDetails(state, $ctrl.dossierId);
        if (newDossier) {
          $ctrl.dossierDetails = newDossier;
          $ctrl.loadDossierInformation();
        }

        const historicTasks = $filter('orderBy')($filter('filter')(get($ctrl.dossierDetails, 'task'), { 'active': false }), 'ended', true);
        const processInfo = historicTasks ? historicTasks.map(task => {
          return {
            id: task.id,
            name: task.name,
            task: task,
            status: 'finished',
            action: task => $state.go('task', { taskId: task.id }),
            hasAction: true,
            canExecute: true,
            actionLabel: 'Open'
          };
        }) : [];
        const pinnedComments = immutable.asMutable(selectPinnedDossierComments(state, $ctrl.dossierId), { deep: true });


        // milestones *CONFIG*
        const importantMilestones = [
          'dossier_ingediend_bank', 'epc_controle_ok', 'dossier_afgekeurd', 'dossier_heractiveerd', 'dossier_geconfirmeerd', 'dossier_goedgekeurd', 'dossier_ingediend_energiehuis', 'klaar_voor_betaling', 'betaling_geimporteerd', 'betaling_uitgevoerd'];
        $ctrl.allMilestones = selectMilestonesForDossier(state, $ctrl.dossierId);
        $ctrl.milestonesToShow = $filter('filter')($ctrl.allMilestones, (value) => importantMilestones.includes(value.key));
        $ctrl.pinnedComments = immutable.asMutable(selectPinnedDossierComments(state, $ctrl.dossierId), { deep: true });

        return {
          dossierDetails: selectDossierDetails(state, $ctrl.dossierId),
          activeTasks: $filter('orderBy')($filter('filter')(get($ctrl.dossierDetails, 'task'), { 'active': true }), 'created', true),
          processInfo,
          pinnedComments
        };
      }

      let getDocHelper = (definitionKey) => {
        return get($filter('orderBy')($filter('filter')(get(this.dossierDetails,'document'),{ definition: { key: definitionKey } },true), 'updatedAt', true),0,undefined);
      };

      // dossier information
      const getDossierInformation = () => {
        const dateFilter = $filter('date');
        const timestampFormat = 'dd/MM/yyyy hh:mm';
        const dossierLatestActivity = dateFilter(get(this.dossierDetails, ['dossier','latestActivity']), timestampFormat);
        const aanvraagDoc = getDocHelper(documentDefinitionKeys.AANVRAAG);
        return [
          { label: 'Dossier type', value: get(this.dossierDetails, ['dossier', 'dossierDefinition', 'label'], '- onbekend -') },
          { label: 'Dossier ID', value: get(this.apiDossier, ['id'], '- onbekend -') },
          { label: 'Type verlener', value: get(aanvraagDoc, [documentDefinitionKeys.AANVRAAG,'type_lender', 'selectedOptionLabel', 'nl'], '- niet ingevuld -') },
          { label: 'Kredietgever', value: get(aanvraagDoc, [documentDefinitionKeys.AANVRAAG,'lender_companyname'], '- niet ingevuld -') },
          { label: 'Kredietnummer', value: get(aanvraagDoc, [documentDefinitionKeys.AANVRAAG,'credit_number'], '- niet ingevuld -') },
          { label: 'Rekeningnummer', value: get(aanvraagDoc, [documentDefinitionKeys.AANVRAAG,'bankaccount_number'], '- niet ingevuld -') },
          { label: 'EPC-attestnummer', value: get(aanvraagDoc, [documentDefinitionKeys.AANVRAAG,'epc_attestnumber'], '- niet ingevuld -') },
          { label: 'Latest activity', value: dossierLatestActivity || '- onbekend -' }
        ]
      };


    }

  });

export { template };
export default namespace;